.App {
  text-align: center;
  height: 100vh;
  overflow: auto;
}

.SDiv {
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(./assets/images/imgThree.png);
  background-position: top center;
  background-repeat: repeat;
  background-color: #FFFFFF;
  background-size: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.span {
  font-family: Sora;
  font-size: 12px;
}

.input {
  font-family: Sora;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #D0D3D4 #FFFFFF;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #FFFFFF;
}

*::-webkit-scrollbar-thumb {
  background-color: #D0D3D4;
  border-radius: 20px;
  border: 3px solid #FFFFFF;
}

input[type=file]::file-selector-button {
  font-family: Sora;
  font-size: 15px;
  margin-right: 20px;
  border: none;
  background: #F1FAFF;
  padding: 8px 10px;
  color: #000000;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}