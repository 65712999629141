body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  color: white !important;
}

.inputfield {
  height: 38px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(195, 190, 190);
  padding-left: 10px;
}
.Banner1 {
  background-image: url("../src/assets/images/Entrepreneurs3.png");
  /* background-color: antiquewhite; */
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center;
  width: 100vw;
  /* height: 350px; */
}
.b1 {
  width: 100%;
  height: auto;
}
.certificate {
  text-decoration: none !important;
  color: white !important;
}

.head {
  /* position: absolute; */
  /* top: 40%; */
  /* left: 40%; */

  /* font-size: 64px; */
  /* font-weight: 700; */
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(
    180deg,
    rgba(255, 107, 0, 0.83) 34.38%,
    rgba(18, 0, 130, 0.53) 50%,
    rgba(82, 206, 24, 0.83) 69.27%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.head2 {
  /* position: absolute; */
  /* top: 40%; */
  /* left: 40%; */

  /* font-size: 64px; */
  /* font-weight: 700; */
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(180deg, #120082 0%, #2f80ed 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.head1 {
  /* position: absolute; */
  /* top: 40%; */
  /* left: 40%; */

  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: #2f80ed;
}

.co {
  color: "#2F80ED" !important;
}
.css-2fdkz6 {
  color: white !important;
}

.imp {
  color: #2f80ed;
}

.lis {
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
}

.css-1wc848c-MuiFormHelperText-root {
  color: red !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

.css-1x5jdmq {
  padding: 8px 14px !important;
}
.css-v7esy {
  color: red !important;
}

@media only screen and (min-width: 771px) {
  .pledge {
    /* position: "absolute"; */
    top: 50%;
    left: 50%;
    height: 85%;
    transform: translate(-50%, -50%);
    width: 80%;
    bgcolor: "background.paper";
    border: none;
    overflow: hidden;
    boxshadow: 24;
  }
}

.pledge {
  position: "absolute";
  top: 50%;
  left: 50%;
  height: 85%;
  transform: translate(-50%, -50%);
  width: 80%;
  /* bgcolor: background.paper; */
  border: none;
  overflow: hidden;
  boxshadow: 24;
}

.Banner4 {
  background-image: url("../src/assets/images/Group32.png");
  background-size: cover;
  background-position: left;
  height: "100%";
}

.Banner5 {
  background-image: url("../src/assets/images/Banner6.jpg");
  background-size: cover;
  background-position: right;
  height: "100%";
}

.Banner6 {
  /* background-image: url("../src/Assets/images/PhotoA.png"); */
  background-size: cover;
  background-position: center;
  height: "100%";
}

.PledgeList {
  border: 1px dashed grey;
  padding: 15px;
  width: 90%;
  border-radius: 10px;
}
.pledgediv {
  background: #fefae0;
}
/* Responsive design styles */
.container {
  position: relative;
  width: 450px;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;

  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;

  transform: translate(-50%, -50%);
  /* -ms-transform: translate(-50%, -50%); */
  text-align: center;
}

.container:hover .image {
  opacity: 0.5;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: rgba(94, 54, 175, 0.33);

  color: white;
  font-size: 16px;
  padding: 16px 32px;
}
.css-dcpq5r {
  max-width: 100% !important;
}
.css-1b2hhj1 {
  max-width: 100% !important;
}
.css-1nft05v-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

.swiper-pagination-bullet {
  background-color: rgb(250, 69, 9) !important;
  width: 30px !important;
  border-radius: 5px !important;
}
